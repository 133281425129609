.front-blog .ql-editor p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.front-blog .ql-editor h1,
.front-blog .ql-editor h2,
.front-blog .ql-editor h3,
.front-blog .ql-editor h4 {
  font-size: 1rem !important;
}

.front-blog .ql-editor {
  line-height: 1.2rem;
}
