.quill {
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 0 2px 0 black;
}

.front-blog-detail .ql-container {
  font-size: 16px;
}
.read-only-quill .ql-container,
.front-blog .ql-container {
  font-size: 13px;
}
.front-blog-detail,
.read-only-quill,
.front-blog {
  background-color: white;
  border-radius: 0rem;
  padding: 0rem;
  height: 6rem;
  overflow: hidden;
}

.front-blog,
.front-blog-detail {
  box-shadow: 0 0 0 0 white;
  border: 0px;
}

.front-blog .ql-editor {
  padding: 0px;
  height: 5rem;
  overflow: hidden;
  border: 0px;
  text-overflow: ellipsis; /* enables ellipsis */
}

.front-blog .ql-editor::before {
  position: absolute;
  content: " ";
  width: 100%;
  bottom: 15px;
  height: 25px;
  background: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, white 100%);
}

.detail .ql-container,
.front-blog-detail .ql-container,
.front-blog .ql-container {
  border: 0px !important;
}

.front-blog .ql-editor p {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: wrap;
}

.front-blog-detail {
  height: auto;
  margin-top: 1rem;
}

.quill.detail,
.front-blog-detail .ql-editor,
.quill.detail .ql-editor {
  padding: 0px;
  box-shadow: 0 0 0 0 white;
  border: 0px;
  border-radius: 0rem;
}
a {
  pointer-events: auto !important;
}

.front-blog-detail .ql-editor {
  line-height: 1.6rem;
  text-align: justify;
  background-color: whitesmoke;
  color: rgba(50, 49, 49, 0.825);
}
