.side-nav:hover {
  color: aliceblue;
}

.MuiTypography-root .btn-yellow {
  border-radius: 1rem;
  background-color: rgb(7, 106, 255);
  color: "#000" !important;
}

.btn-blue {
  border-radius: 1rem;
  background-color: rgb(7, 106, 255);
  color: "#000" !important;
  border: 1px solid black;
}

/* .MuiDrawer-paper {
  background-color: red !important;
  box-shadow: 0px 0px 25px yellow !important;
} */
