::-webkit-scrollbar {
  width: 0.1rem;
  background: rgb(138, 131, 131, 0.5);
  /* opacity: 0; */
}
.goog-te-banner-frame {
  display: none !important;
}

body {
  top: 0px !important;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar:hover {
  opacity: 1;
}
::-webkit-scrollbar-thumb {
  width: 1rem;
  border-radius: 2rem;
  /* box-shadow: 1px 0px 4px 2px black; */
background-color: rgb(56 68 80);
  /* opacity: 0; */
  z-index: -1;
}

/* ::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
} */

::-webkit-scrollbar-thumb:hover {
  opacity: 1;
  box-shadow: 0 0 1 #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the handle when hovered */
}

/* button styles */
.button {
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: #16b8f3;
  color: white;
  border-radius: 5px;
  transition: 0.3s background-color ease-out;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* for test select prevent in HIW TEST */
.disable-text-selection {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  font-size: large;
  line-height: 180%;
}

.checkBg {
  background-color: yellow !important;
}

.wrongBg {
  background-color: red !important;
}

.correctBg {
  background-color: greenyellow !important;
}

.spanStyle {
  font-size: large;
  line-height: 170%;
}

.bg0 {
  border-color: red;
}

.bg1 {
  border-color: rgb(0, 255, 21);
}

.correct {
  border-color: greenyellow !important;
}

.false {
  border-color: red !important;
}

.drop span {
  background: rgba(51, 170, 51, 0) !important;

  border: 0px white !important;
  border-radius: "1rem";
  text-align: center;
  font-size: 1rem;
  height: 2rem;

  font-weight: 500;
  color: #ffc107 !important;
}

.zig-zag::before {
  background: linear-gradient(-45deg, transparent 16px, #bac334 0),
    linear-gradient(45deg, transparent 16px, #bac344 0);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 12px 22px;
  content: "";
  display: block;

  width: 100%;
  height: 32px;

  position: relative;
  /* top: 64px;
  left: 0px;
  position: relative;
  bottom: 64px; */
  /* left: 0; */
}

/* front end cardui */
.zig-zag-test {
  background-color: yellow;
  background: linear-gradient(-45deg, transparent 16px, #f3fd62 0),
    linear-gradient(45deg, transparent 16px, #f2ff3e 0);
  background-repeat: repeat-x;
  background-position: 50% 36%;
  width: 100%;
  background-size: 12px 22px;
}
/* chat  */
.fb_dialog_content iframe {
  right: 2px !important;
  bottom: 55px !important;
}

#crisp-chatbox a[role="button"] {
  bottom: 60px !important;
  right: 15px !important;
}
