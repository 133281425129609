.carousel-container-backend {
  width: 70vw;
  margin: 0 auto;
}

.carousel-container-frontend {
  width: 100%;
  margin: 0 auto;
}

.training-carousel-container-backend {
  width: 70vw;
  margin: 0 auto;
}
