.swal-button-cancel {
    background-color: #bfbfbf; /* Gray color for Cancel */
    color: white;
  }
  
  .swal-button-deny {
    background-color: #f27474; /* Red color for Don't Save */
    color: white;
  }
  
  .swal-button-confirm {
    background-color: #a5dc86; /* Green color for Save */
    color: white;
  }
  