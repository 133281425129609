/* custom css for carousel */
.carousel-slider {
  /* width: 100vw; */
  /* height: 80vh; */
}

.slide div {
  /* height: 80vh; */
}

.pte-start-carousel .carousel .control-dots .dot {
  width: 13px;
  height: 13px;
  opacity: 1;
  margin: 5px;
}

.control-dots .dot.selected {
  background-color: yellow !important;
}

/* .pte-start-info {
  position: relative;
} */

/* .pte-bg-filter {
  position: absolute;
  width: 100vw;
  height: 60vh;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(74, 134, 206, 0.6);
  backdrop-filter: blur(2px);
} */

/* .pte-img {
  position: absolute;
  left: 0;
  height: "100vh";
} */

/* .pte-img img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: "20rem" !important;
} */

/* 
.pte-button {
  position: absolute;
  width: 60% !important;
  height: 35%;
  top: 0;
  left: 1;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: auto;
  color: rgb(245, 239, 239);
  z-index: 4;
  text-align: left;
  transition: 1s ease;
} */

/* .pte-button h2 {
  font-size: 2rem;
}

.pte-button h5 {
  font-size: 1.5rem;
  font-weight: 400;
} */
.pte-img {
  height: 60vh;
}

/* .btn {
  width: 8rem;
  height: 2.5rem;
  margin-top: 1rem;
  border-radius: 0.2rem;
  border: none;
  cursor: pointer;
  background-color: yellow;
  transition: 0.3s ease;
} */

/* .btn:hover {
  background-color: aliceblue;
}

.btn:active {
  background-color: brown;
} */
/* 
@media (max-width: 2150px) {
  .pte-start-carousel .carousel-slider {
    width: 100vw;
    height: 90vh;
  }

  .pte-start-carousel .slide div {
    height: 90vh;
  }
} */

/* @media (max-width: 1655px) {
  .pte-start-carousel .carousel-slider {
    width: 100vw;
    height: 80vh;
  }

  .pte-start-carousel .slide div {
    height: 80vh;
  }
}

@media (max-width: 1478px) {
  .pte-start-carousel .carousel-slider {
    width: 100vw;
    height: 67vh;
  }

  .pte-start-carousel .slide div {
    height: 67vh;
  }
}

@media (max-width: 1227px) {
  .pte-start-carousel .carousel-slider {
    width: 100vw;
    height: 55vh;
  }

  .pte-start-carousel .slide div {
    height: 55vh;
  }
}

@media (max-width: 1006px) {
  .pte-start-carousel .carousel-slider {
    height: 50vh;
  }

  .pte-start-carousel .slide div {
    height: 50vh;
  }
}

@media (max-width: 900px) {
  .pte-banner {
    width: 90%;
    height: 100%;
  }
}  */

/* @media (min-width: 2200px) {
  .bg-banner {
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media (max-width: 875px) {
  .pte-button {
    width: 100%;
    left: 0;
    margin: 0; 
    padding: 0;
    font-size: "1.2rem";
  }
}



/* @media (max-width: 375px) {
  .bg-banner {
    background-position: center;
     background-position-x: -500px; 
  } */
/* 
  .pte-button {
    width: 80% !important;
    margin: auto auto;
  }
  .pte-button h2,
  h5 {
    font-size: 18px !important;
  }

  .pte-button .btn {
    font-size: 13px;
    width: 6rem;
    height: 2rem;
  }

  .pte-start-carousel .carousel {
    height: 400px !important;
  }

  .bg-banner {
    background-position: 20% 50%;
    height: 400px !important;
    background-repeat: no-repeat;
    background-size: cover;
  } */
/* } */
/* .carousel .slide {
  z-index: 5 !important;
} */

@media (max-width: 500px) {
  .pte-start-carousel .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
  }
}
/* 
.slide {
  z-index: 2 !important;
} */
